::ng-deep {
	mat-row,
	mat-footer-row {
		height: 36px !important;
		min-height: 36px !important;
	}

	mat-row:not(.detail-row):hover {
		background-color: #ffe8d5 !important;
	}

	mat-header-row {
		min-height: 46px !important;
	}

	.ft-patient-link {
		text-decoration: none;
		color: inherit;

		&:hover {
			color: #00aaff;
		}
	}

	.mat-column-patientName {
		min-width: 200px !important;
	}

	.mat-column-procedureCode,
	.mat-footer-procedureCode {
		min-width: 180px;
	}

	.mat-column-patientName,
	.mat-column-patient_fullName,
	.mat-footer-patientName,
	.mat-footer-patient_fullName {
		min-width: 160px;
	}

	.mat-column-referringPhysician,
	.mat-footer-referringPhysician,
	.mat-column-patientID,
	.mat-footer-patientID {
		min-width: 130px;
		max-width: 130px;
	}

	.mat-column-action,
	.mat-footer-action {
		min-width: 160px;
	}

	.mat-column-examType,
	.mat-column-title_value,
	.mat-column-demographic_gender_description,
	.mat-column-imagingOrder_reasonForExam_value,
	.mat-footer-examType,
	.mat-footer-imagingOrder_reasonForExam_value,
	.mat-column-assigningComment,
	.mat-footer-assigningComment,
	.mat-column-patientMedicalHistory,
	.mat-footer-patientMedicalHistory {
		min-width: 72px;
		max-width: 72px;
		text-align: center;
	}

	.mat-column-demographic_dateOfBirth,
	.mat-column-demographic_maritalStatus_description,
	.mat-column-createdDate,
	.mat-column-patientArrival,
	.mat-column-insuranceName,
	.mat-column-appointmentDateTime,
	.mat-footer-patientArrival,
	.mat-footer-insuranceName,
	.mat-footer-appointmentDateTime,
	.mat-column-scheduledProcedureStepStartDateTime,
	.mat-footer-scheduledProcedureStepStartDateTime,
	.mat-column-reportDelivered,
	.mat-footer-reportDelivered,
	.mat-column-reportSignature,
	.mat-footer-reportSignature {
		max-width: 126px !important;
		min-width: 126px !important;
	}

	.mat-column-completedReportStatus,
	.mat-column-reportStatus,
	.mat-column-urgent,
	.mat-column-billed,
	.mat-column-count,
	.mat-footer-completedReportStatus,
	.mat-footer-reportStatus,
	.mat-footer-urgent,
	.mat-footer-billed,
	.mat-footer-count {
		max-width: 36px;
		min-width: 36px;
		text-align: center;
	}

	.mat-column-patientClass,
	.mat-column-editingState,
	.mat-column-patientStatus,
	.mat-column-reportingStatus,
	.mat-column-paymentStatus,
	.mat-footer-patientClass,
	.mat-footer-editingState,
	.mat-footer-patientStatus,
	.mat-footer-reportingStatus,
	.mat-footer-paymentStatus,
	.mat-column-noteAlert,
	.mat-footer-noteAlert {
		max-width: 46px;
		min-width: 46px;
		text-align: center;
	}

	.mat-column-totalAmount,
	.mat-column-paidAmount,
	.mat-column-leftAmount,
	.mat-column-globalDiscount {
		text-align: left !important;
		min-width: 80px;
		max-width: 100px;
	}

	.mat-column-startDate {
		max-width: 80px !important;
	}

	.mat-column-action {
		max-width: 140px !important;
	}

	.mat-column-startTime {
		max-width: 56px !important;
	}

	.mat-column-patient_fullName,
	.mat-column-referringPhysician_fullName {
		max-width: 180px !important;
	}

	.mat-column-appointmentReason {
		max-width: 200px !important;
	}

	.mat-column-appointmentStatus {
		max-width: 100px !important;
		text-align: center;
	}

	.mat-column-appointmentSource,
	.mat-column-priority_value,
	.mat-column-examType,
	.mat-column-modality {
		max-width: 64px !important;
		text-align: center;
	}

	.mat-column-confidentiality {
		min-width: 44px !important;
		max-width: 44px !important;
		text-align: center;
	}

	.mat-column-examinationStarted,
	.mat-column-examinationFinished {
		text-align: center !important;
		max-width: 110px !important;
	}

	.mat-column-sideEffects {
		text-align: center;
		max-width: 60px;
		min-width: 46px;
	}

	.mat-column-confidentialityCode,
	.mat-column-hidden {
		max-width: 44px;
		text-align: center;
	}

	.mat-column-action {
		color: #546e7a;
	}

	.mdc-data-table__cell,
	.mdc-data-table__header-cell,
	.mdc-data-table__footer-cell {
		padding: 0 8px !important;
	}

	.detail-row {
		height: 0 !important;
		min-height: 0 !important;
		max-height: 0 !important;
		opacity: 0;
		transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1);

		&.visible {
			opacity: 1;
			height: 120px !important;
			max-height: 120px !important;
			min-height: auto !important;
			overflow: auto;
		}
	}
}

.example-element-row {
	cursor: pointer;
	border-bottom: 0 solid rgba(0, 0, 0, 0.12);

	&.active {
		border-bottom-color: transparent;
		font-weight: bold;
	}
}

::ng-deep.example-element-detail {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding: 6px;
	width: 100%;
}

.expandable-rows-table {
	::ng-deep.mat-column-expandedRow {
		height: fit-content !important;
		//max-height: 140px;
		overflow: auto !important;
	}
}

::ng-deep table.nested-table {
	padding: 2px;
	border-spacing: 0;

	th {
		font-weight: 600 !important;
		text-align: left !important;
		font-size: 12px;
		border-bottom: 1px solid #03a9f4;
	}

	td:last-child {
		padding: 0;
		float: right;
		height: 26px;
		line-height: 26px;
	}
}

.fx-table-empty {
	height: 200px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

:host-context(.dark-theme) {
	::ng-deep mat-row:not(.detail-row):hover {
		background-color: #252538 !important;
	}
}

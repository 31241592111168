@use 'sass:map';
@use '../utils/theme-colors' as colors;
@use '@angular/material' as mat;

@use '../typography/config' as typography;
@use '../utils/index' as util;

$primary-palette: map.merge(
	mat.$red-palette,
	(
		600: map.get(colors.$dark, 'primary'),
		contrast: (
			600: white,
		),
	)
);

$ft-primary: mat.define-palette($primary-palette, 600);
$ft-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$ft-warn: mat.define-palette(mat.$red-palette, 500);

$ft-dark-theme: mat.define-dark-theme(
	(
		color: (
			primary: $ft-primary,
			accent: $ft-accent,
			warn: $ft-warn,
		),
		density: -2,
		typography: typography.$ft-typography,
	)
);

$ft-dark-theme: util.mat-set-background(
	$ft-dark-theme,
	map.get(colors.$dark, 'app-background')
);
$ft-dark-theme: util.change-theme-background(
	$ft-dark-theme,
	'card',
	map.get(colors.$dark, 'card')
);
$ft-dark-theme: util.change-theme-background(
	$ft-dark-theme,
	'dialog',
	map.get(colors.$dark, 'dialog')
);

.dark-theme {
	@include mat.all-component-colors($ft-dark-theme);
}

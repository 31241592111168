@use 'sass:map';
@use '@angular/material' as mat;
@use './styles/themes/dark' as dark;
@use './styles/themes/light' as light;
@use './styles/utils/theme-colors' as colors;

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.snow.css';
@import './styles/components/icons';
@import './styles/utils/plyr';
@import 'flexmonster/flexmonster.min.css';
//@import '~webdatarocks/webdatarocks.min.css';

@import './styles/utils/layout';
@import './styles/components/dialog';
@import './styles/components/view-card';
@import './styles/components/table';
@import './styles/components/menu';
@import './styles/components/form-field';
@import './styles/components/button-toggle';
@import './styles/components/select-option';

@import './styles/breakpoints/breakpoints';

@include mat.core();
@include mat.all-component-themes(light.$ft-light-theme);

//@tailwind base;
//@tailwind components;
//@tailwind utilities;

$loading-color: map.get(colors.$light, 'primary');

html {
	height: 100%;
	--scrollbarBG: #f5f3f6;
	--thumbBG: rgba(208, 208, 208, 0.45);
	--thumbDarkBG: #2f2f41;
}

body {
	margin: 0;
	height: 100%;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	scrollbar-width: thin;
	scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

/* Scroll*/
::-webkit-scrollbar {
	width: 10px;
	height: 11px;
}

::-webkit-scrollbar-thumb {
	background-color: var(--thumbBG);
	border-radius: 6px;
}

::-webkit-scrollbar-track {
	background: var(--scrollbarBG);
}

.dark-theme ::-webkit-scrollbar-track {
	background: var(--thumbDarkBG);
}

::-webkit-scrollbar-corner {
	background: var(--scrollbarBG);
}

.dark-theme ::-webkit-scrollbar-corner {
	background: var(--thumbDarkBG);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.example-loading-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 56px;
	right: 0;
	background: transparent;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.example-rate-limit-reached {
	color: #980000;
	max-width: 360px;
	text-align: center;
}

.spinner-box {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: transparent;
}

.circle-border {
	width: 150px;
	height: 150px;
	padding: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	background: linear-gradient(0deg, rgb(0 0 0 / 0%) 33%, $loading-color 100%);
	animation: spin 0.8s linear infinite;
}

.circle-core {
	width: 100%;
	height: 100%;
	background: map.get(colors.$light, 'app-background');
	border-radius: 50%;
}

.dark-theme .circle-core {
	background: map.get(colors.$dark, 'app-background');
}

.loader-text {
	position: absolute;
	color: $loading-color;
	font-weight: bold;
	font-size: 14px;
	font-family: sans-serif;
}

.loader-text img {
	height: 28px;
	filter: drop-shadow(-1px 1px 6px rgba($loading-color, 0.3));
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.mat-mdc-floating-label {
	top: 50% !important;
}

.mdc-floating-label--float-above {
	font-weight: bold !important;
	font-size: 14px !important;
	height: 26px !important;
	line-height: 26px !important;
}
